<template>
  <el-scrollbar :style="{height}">
    <table v-for="(table, index) in tableList" :key="index" class="tabCss">
      <caption
        v-show="table.captionName"
        class="captionCss"
        :style="{
          'border-top': notHasLeftAndRightBorder  ? 'none': tableBorder,
          'border-left': notHasLeftAndRightBorder ? 'none': tableBorder,
          'border-right': notHasLeftAndRightBorder ? 'none': tableBorder,
          'border-bottom': tableBorder
        }"
      >{{ table.captionName }}</caption>
      <tbody>
        <tr v-if="table.tableTitleTr">
          <th
            v-for="(tableTh, tableThIndex) in table.tableTitleTr"
            :key="tableThIndex"
            :colspan="tableTh.colspan"
            :rowspan="tableTh.rowspan"
            :style="{
              width: tableTh.width, 
              'text-align': tableTh.textAlign, 
              'border-top': notHasLeftAndRightBorder || (table.captionName && table.captionName !== '') ? 'none': tableBorder,
              'border-left': notHasLeftAndRightBorder ? 'none': tableBorder,
              'border-right': notHasLeftAndRightBorder || tableThIndex !== table.tableTitleTr.length - 1 ? 'none': tableBorder,
              'border-bottom': tableBorder,
              color: tableTh.fontColor
            }"
          >{{ tableTh.thName }}</th>
        </tr>
        <tr
          v-for="(tableTr,tableTrIndex) in table.tableTrArr"
          :key="tableTrIndex"
          class="trTdCss"
          @click="clickTr(tableTr)"
          @contextmenu.prevent="rightClick(tableTr)"
        >
          <td
            v-for="(tableTd, tableTdIndex) in tableTr.tableTdArr"
            :key="tableTdIndex"
            :colspan="tableTd.colspan"
            :rowspan="tableTd.rowspan"
            :style="{
              width: tableTd.width,
              'text-align': tableTd.textAlign,
              'border-left': notHasLeftAndRightBorder ? 'none': tableBorder,
              'border-right': notHasLeftAndRightBorder || tableTdIndex !== tableTr.tableTdArr.length - 1 ? 'none': tableBorder,
              'border-bottom': tableBorder,
              color: tableTd.fontColor,
              'font-size': tableTd.fontSize
            }"
          >
            <div v-if="!tableTd.type">
              {{ tableTd.tdName }}
            </div>
            <div v-if="tableTd.type === 'status'">
              <div class="statusCss" :style="{'background-color': tableTd.color}" />
            </div>
            <div v-if="tableTd.type === 'tag'">
              <el-tag :type="tableTd.status ? tableTd.status : 'danger'" effect="dark" size="mini">{{ tableTd.tdName }}</el-tag>
            </div>
            <div v-if="tableTd.type === 'button'">
              <el-button v-if="!tableTd.butType" :type="tableTd.butTypeCss" size="mini" @click="clickBut(tableTr, tableTd)">{{ tableTd.tdName }}</el-button>
              <div v-if="tableTd.butType === 'textBut'" class="textButCss" @click="clickBut(tableTr, tableTd)">{{ tableTd.tdName }}</div>
              <a v-if="tableTd.butType === 'aBut'" target="_blank" :href="tableTd.url">{{ tableTd.tdName }}</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </el-scrollbar>
</template>

<script>
export default {
  props: {
    notHasLeftAndRightBorder: { // 没有左右边框
      type: Boolean,
      default: true
    },
    tableBorder: {
      type: String,
      default: '1px solid rgba(128,214,255, 0.4)'
    },
    height: {
      type: String,
      default: '6vw'
    },
    tableList: {
      type: Array,
      default: () => {
        /* 可对单元格设置参数：
          colspan 横跨列数
          rowspan 纵跨行数
          width 宽度
          textAlign 对齐
          paddingLeft 内左边距
        */
        return [
          {
            captionName: '采集端协议列表',
            tableTitleTr: [
              { thName: '日期', textAlign: 'center', colspan: 2, rowspan: 2, fontColor: '#000' },
              { thName: 'COD（mh/L）', textAlign: 'center', colspan: 2, rowspan: 1, fontColor: '#000' },
              { thName: 'PAC用量（kg）', textAlign: 'center', colspan: 1, rowspan: 2, fontColor: '#000' },
              { thName: '单位电耗（kwh/km³）', textAlign: 'center', colspan: 1, rowspan: 2, fontColor: '#000' }
            ],
            tableTrArr: [
              {
                tableTdArr: [{ tdName: '进水', textAlign: 'center', fontColor: '#000' }, { tdName: '出水', textAlign: 'center', fontColor: '#000' }]
              },

              {
                tableTdArr: [
                  { tdName: '2022/1/1', textAlign: 'center', colspan: 2 }, { tdName: '225.00', textAlign: 'center', rowspan: 3 }, { tdName: '30.00', textAlign: 'center', rowspan: 3 },
                  { tdName: '50.0', textAlign: 'center' }, { tdName: '0.00', textAlign: 'center', rowspan: 50 }
                ]
              },
              {
                tableTdArr: [
                  { tdName: '2022/1/2', textAlign: 'center', colspan: 2 },
                  { tdName: '60.0', textAlign: 'center' }
                ]
              },
              {
                tableTdArr: [
                  { tdName: '2022/1/3', textAlign: 'center', colspan: 2 },
                  { tdName: '70.0', textAlign: 'center' }
                ]
              },

              {
                tableTdArr: [
                  { tdName: '2022/1/4', textAlign: 'center', colspan: 2 }, { tdName: '220.00', textAlign: 'center', rowspan: 3 }, { tdName: '35.00', textAlign: 'center', rowspan: 3 },
                  { tdName: '80.0', textAlign: 'center' }
                ]
              },
              {
                tableTdArr: [
                  { tdName: '2022/1/5', textAlign: 'center', colspan: 2 },
                  { tdName: '90.0', textAlign: 'center' }
                ]
              },
              {
                tableTdArr: [
                  { tdName: '2022/1/6', textAlign: 'center', colspan: 2 },
                  { tdName: '100.0', textAlign: 'center' }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  computed: {
    paddingLeft() {
      return (index, tableTrIndex, tableTdIndex) => {
        if (this.tableList[index].tableTrArr[tableTrIndex].tableTdArr[tableTdIndex].paddingLeft) {
          return this.tableList[index].tableTrArr[tableTrIndex].tableTdArr[tableTdIndex].paddingLeft
        } else if (this.tableList[index].tableTrArr[tableTrIndex].tableTdArr[tableTdIndex].textAlign !== 'center') {
          return '0.5vw'
        }
      }
    }
  },
  methods: {
    clickTr(tableTr) {
      this.$emit('clickTr', tableTr)
    },
    rightClick(tableTr) {
      this.$emit('rightClick', tableTr)
    },
    clickBut(tableTr, tableTd) {
      this.$emit('clickBut', {
        tableTr,
        tableTd
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.tabCss {
  width 100%
  border-spacing 0 // 去掉单元格间隙
  font-weight bolder
  .captionCss {
    text-align center
    background-color rgba(60,184,243, 0)
    color white
    height 2vw
    line-height 2vw
  }
  th, td {
    font-size 0.5vw
    background-color rgba(255,255,255, 0)
    line-height 2vw
    white-space pre-wrap // 保留空白符序列，但是正常地进行换行
    .textButCss {
      cursor pointer // 鼠标指针便手
      color #e84c3d
    }
  }
  th {
    color rgb(226,226,226)
  }
  td {
    color rgb(168,168,168)
    padding 0.3vw 0
    .statusCss {
      margin-left calc(50%  - 0.8vw)
      width 1.6vw
      height 1.6vw
      border-radius 50%
    }
  }
  .trTdCss:hover {
    box-shadow 0 0 0.5vw rgba(25,186,139, 1) inset
  }
}
</style>
