<template>
  <div class="produOrderInfoCss">
    <div v-for="(orderInfo, index) in orderInfo.orderInfoList" :key="index">
      <div class="titleCss">{{orderInfo.title}}</div>
      <div class="msgCss">{{orderInfo.msg}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orderInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.produOrderInfoCss {
  color white
  font-size 0.8rem
  line-height 1.3rem
  .titleCss {
    margin-top 0.6rem
    font-weight bolder
  }
  .msgCss {
    text-indent 1.6rem
  }
}
</style>
