<template>
  <div class="produSpecifCss">
    <div v-for="(specifMsg, index) in produSpecifMsg.specifMsgList" :key="index">
      <div class="titleCss">{{specifMsg.title}}</div>
      <Table
        :tableList="specifMsg.tableList"
      />
    </div>
  </div>
</template>

<script>
import Table from '@/components/myComp/table/Table.vue'

export default {
  components: {
    Table
  },
  props: {
    produSpecifMsg: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  created() {
    console.log(this.produSpecifMsg)
  }
}
</script>

<style lang="stylus" scoped>
.produSpecifCss {
  color white
  font-size 0.8rem
  .titleCss {
    margin-top 0.8rem
    color #3CB8F3
    font-size 1rem
    height 2rem
    line-height 2rem
  }
}
</style>
