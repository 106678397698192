<template>
  <div class="produOvervCss">

    <!-- 概述 -->
    <div v-for="(overview, index) in produOvervMsg.overviewArr" :key="index">
      <div class="overviewCss">
        <p>{{overview.msg}}</p>
      </div>
      <div v-for="(url, i) in overview.imgs" :key="i">
        <img :src="url" />
      </div>
    </div>

    <!-- 特点和特点例子 -->
    <div class="charactCss">
      <div>{{produOvervMsg.charactTitle}}</div>
      <div class="charactMsgCss">
        <p v-for="(charact, index) in produOvervMsg.charactArr" :key="index"><span>●</span>{{charact}}</p>
      </div>
      <div>{{produOvervMsg.charactExampTitle}}</div>
      <div class="charactExampCss">
        <p v-for="(charactExamp, index) in produOvervMsg.charactExampArr" :key="index"><span>√</span>{{charactExamp}}</p>
      </div>
    </div>

    <!-- 接口资源 -->
    <div class="iteResouTitleCss">{{ produOvervMsg.iteResouTitle }}</div>
    <div class="iteResouItemCss" v-for="(iteResou, index) in produOvervMsg.iteResouList" :key="index">
      <div class="titleCss">{{iteResou.title}}</div>
      <Table
        :tableList="iteResou.tableList"
      />
    </div>

    <!-- 应用场景 -->
    <div class="charactCss">
      <div>{{produOvervMsg.appliScenTitle}}</div>
      <div class="charactMsgCss">
        <p v-for="(appliScen, index) in produOvervMsg.appliScenArr" :key="index"><span>●</span>{{appliScen}}</p>
      </div>
    </div>

    <!-- 概述配图 -->
    <div class="overvTitleCss">{{produOvervMsg.overvTitle}}</div>
    <div class="imgContentCss">
      <div v-for="(overv, index) in produOvervMsg.overvImgs" :key="index">
        <div class="titleCss">{{overv.title}}</div>
        <img :src="overv.url">
      </div>
    </div>

    <!-- 订购信息 -->
    <div class="dimensionsCss">
      <div class="titleCss">{{produOvervMsg.dimensionsTitle}}</div>
      <div class="msgCss">{{produOvervMsg.dimensionsMsg}}</div>
    </div>
  </div>
</template>

<script>
import Table from '@/components/myComp/table/Table.vue'

export default {
  components: {
    Table
  },
  props: {
    produOvervMsg: {
      type: Object,
      default: () => {
        return { // 产品概述
          overviewArr: [], // 概述
          charactTitle: '', // 特点标题
          charactArr: [], // 特点
          charactExampTitle: '', // 特点例子标题
          charactExampArr: [], // 特点列子
          tableList: [], // 表格内容
          overvImgs: [] // 概述配图
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.produOvervCss {
  color white
  font-size 0.8rem
  img {
    max-width 38rem
  }
  .overviewCss {
    text-indent 1.6rem
  }
  .charactCss {
    margin-top 1rem
    .charactMsgCss {
      text-indent 1.6rem
      span {
        margin-right 0.5rem
      }
    }
    .charactExampCss {
      text-indent 1.6rem
      span {
        margin-right 0.5rem
      }
    }
  }
  .iteResouTitleCss {
    color white
    font-size 1rem
  }
  .iteResouItemCss {
    margin-top: 0.3rem
    font-size 0.8rem
    .titleCss {
      color #3CB8F3
      height 1.5rem
      line-height 1.5rem
    }
  }
  .overvTitleCss{
    color white
    font-size 1rem
  }
  .imgContentCss {
    .titleCss {
      color #3CB8F3
      height 1.5rem
      line-height 1.5rem
    }
  }
  .dimensionsCss {
    color white
    font-size 0.8rem
    line-height 1.3rem
    .titleCss {
      margin-top 0.6rem
    }
    .msgCss {
      text-indent 1.6rem
    }
  }
}

</style>
