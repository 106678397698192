<template>
  <div class="productCss">
    <Navbar :pageName="pageName" />
    <div class="contentMsgCss">
      <div class="titleCss">{{productMsg.title}}</div>
      <div class="contentLeftCss" :style="{height: contentLeftHeight}">
        <div ref="contentLeftContentRef">
          <div class="contentLeftLogoCss">
            <div class="contentLeftSvgIconCss">
              <svg-icon icon-class="photograph"  width="0.8rem" height="0.8rem" color="#E84C3D" />
            </div>
            <div class="contentLeftLogoNameCss">产品图片</div>
          </div>
          <div class="flexibleSwiperContentCss">
            <FlexibleSwiper
              :showIcon="false"
              :slidesPerView="1"
              :imageArr="productMsg.productImgs"
            />
          </div>
        </div>
      </div>
      <div class="contentRightCss" ref="contentRightRef">
        <Tab
          :cur="productMsgType"
          :tabTitleArr="tabTitleArr"
          isSelectBackColor="#e84c3d"
          @change="tabChange"
        />
        <ProduOverv v-if="productMsgType === 0" :produOvervMsg="productMsg.produOverv" />
        <ProduSpecif v-if="productMsgType === 1" :produSpecifMsg="productMsg.produSpecifMsg" />
        <ProduOrderInfo v-if="productMsgType === 2" :orderInfo="productMsg.orderInfo" />
      </div>
     
    </div>
  </div>
</template>

<script>
import Navbar from '@/views/home/components/Navbar.vue'
import FlexibleSwiper from '@/components/myComp/swiper/FlexibleSwiper.vue'
import Tab from '@/components/myComp/tab/Tab.vue'
import ProduOverv from './components/ProduOverv.vue'
import ProduSpecif from './components/ProduSpecif.vue'
import ProduOrderInfo from './components/ProduOrderInfo.vue'
import { wlwzncjzd, wlwbyznkzq, jcsjcjwg, mqttDTU, fbsIO, deviceCloud, wsczzhjkyypt, iCSOSS } from '@/assets/basicData.js'

export default {
  name: 'product',
  components: {
    Navbar,
    FlexibleSwiper,
    Tab,
    ProduOverv,
    ProduSpecif,
    ProduOrderInfo
  },
  data() {
    return {
      pageName: '',
      contentLeftHeight: 'auto', // 左侧容器高度
      productMsg: { // 产品信息
        title: '',
        productImgs: [], // 产品图片
        produOverv: { // 产品概述
          overviewArr: [], // 概述
          charactTitle: '', // 特点标题
          charactArr: [], // 特点
          charactExampTitle: '', // 特点例子标题
          charactExampArr: [], // 特点列子
          tableList: [], // 表格内容
          overvImgs: [] // 概述配图
        },
        produSpecifMsg: { // 产品规格
          overvMsgList: [] // 规格列表
        },
        orderInfo: { // 订购信息
          orderInfoList: [] // 订购信息列表
        }
      },
      tabTitleArr: [
        { title: '产品概述', iconName: "wordFile" },
        { title: '产品规格', iconName: "specifi" },
        { title: '订购信息', iconName: "star" }
      ],
      productMsgType: 0, // 展示的产品内容类型

      wlwzncjzd, // 物联网智能采集终端
      wlwbyznkzq, // 物联网边缘智能控制器
      jcsjcjwg, // 机床数据采集网关
      mqttDTU, // MQTT DTU
      fbsIO, // 分布式IO
      deviceCloud, // 设备云
      wsczzhjkyypt, // 污水厂站智慧集控运营平台
      iCSOSS // 污水站智能控制系统
    }
  },
  mounted() {
    this.pageName = this.$route.query.pageName
    this.setProductMsg()
    this.setContentLeftHeight()
    window.onresize = () => { // 监听浏览器大小变化
      this.setContentLeftHeight()
    }
    window.addEventListener('setItem', () => {
      this.pageName = localStorage.getItem('pageName')
      this.setProductMsg()
    })
  },
  destroyed() {
    localStorage.removeItem('pageName')
  },
  methods: {
    setContentLeftHeight() { // 设置左侧容器高度
      setTimeout(() => {
        const contentRightHeight = this.$refs.contentRightRef.offsetHeight
        const contentLeftHeight = this.$refs.contentLeftContentRef.offsetHeight
        this.contentLeftHeight = contentRightHeight > contentLeftHeight * 1.1 ? contentRightHeight + 'px' : contentLeftHeight * 1.1 + 'px'
      }, 300)
    },
    setProductMsg() {
      switch(this.pageName) {
        case '物联网智能采集终端' : this.productMsg = this.$lodash.cloneDeep(this.wlwzncjzd); break
        case '物联网边缘智能控制器' : this.productMsg = this.$lodash.cloneDeep(this.wlwbyznkzq); break
        case '机床数据采集网关' : this.productMsg = this.$lodash.cloneDeep(this.jcsjcjwg); break
        case 'MQTT DTU' : this.productMsg = this.$lodash.cloneDeep(this.mqttDTU); break
        case '分布式IO' : this.productMsg = this.$lodash.cloneDeep(this.fbsIO); break
        case '设备云' : this.productMsg = this.$lodash.cloneDeep(this.deviceCloud); break
        case '污水厂站智慧集控运营平台' : this.productMsg = this.$lodash.cloneDeep(this.wsczzhjkyypt); break
        case '污水站智能控制系统' : this.productMsg = this.$lodash.cloneDeep(this.iCSOSS); break
      }
      this.setContentLeftHeight()
    },
    tabChange(e) {
      this.productMsgType = e
      this.setContentLeftHeight()
    }
  }
}

</script>

<style lang="stylus" scoped>
contentLeftMargin = 0.8rem

.productCss {
  width 99.8rem
  background-color #333439
  .contentMsgCss {
    padding 1rem 15rem
    .titleCss {
      margin-left contentLeftMargin
      height 4rem
      line-height 4rem
      font-size 2rem
      color white
      font-weight bolder
    }
    .contentLeftCss {
      float left
      width 22rem
      padding-right 2rem
      height 95%
      border-right 1px solid white
      .contentLeftLogoCss {
        margin 1rem 0 1rem contentLeftMargin
        width 7rem
        height 2.5rem
        background-color white
        .contentLeftSvgIconCss {
          float left
          margin 0.6rem 0.5rem 0 1rem
        }
        .contentLeftLogoNameCss {
          height 2.5rem
          line-height 2.5rem
          font-size 0.8rem
          font-weight bold
          color #E84C3D
        }
      }
      .flexibleSwiperContentCss {
        width 100%
        height 14rem
      }
    }
    .contentRightCss {
      float left
      margin-left 3rem
      width 38rem
    }
    .contentRightCss:after {
      clear both
      content ''
      height 0
      display block
      visibility hidden
    }
  }
  .contentMsgCss:after { // 使 contentMsgCss 适应内部高度
    clear both
    content ''
    width 0
    height 0
    display block
    visibility hidden
  }
}
</style>